<script>
import Layout from '@/views/layouts/main'
import { onMounted, ref, watch } from 'vue'
import { getTerms } from './api'
import AppPagination from '@/components/app-pagination'
import Notify from '@/notify'
import debounce from '@/utils/debounce'

const statusMap = {
  ACTIVE: {
    label: 'Ativo',
    class: 'badge-soft-success'
  },
  INACTIVE: {
    label: 'Inativo',
    class: 'badge-soft-danger'
  }
}

export default {
  setup () {
    const termsList = ref([])
    const page = ref(1)
    const pages = ref(0)
    const total = ref(0)
    const filter = ref({
      status: null,
      created_at: {
        from: null,
        to: null
      },
      confirmed_at: {
        from: null,
        to: null
      }
    })

    onMounted(() => {
      fetchTerms()
    })

    const dateToISO = (date) => {
      if (date && date.length === 10) {
        return date.split('/').reverse().join('-')
      }
      return null
    }

    const fetchTerms = debounce(() => {
      const params = {
        page: page.value,
        status: filter.value.status,
        created_at: {
          gte: dateToISO(filter.value.created_at.from),
          lte: dateToISO(filter.value.created_at.to)
        },
        confirmed_at: {
          gte: dateToISO(filter.value.confirmed_at.from),
          lte: dateToISO(filter.value.confirmed_at.to)
        },
        order: {
          created_at: 'desc'
        }
      }

      getTerms(params).then((data) => {
        termsList.value = data.data
        pages.value = data.meta.last_page
        total.value = data.meta.total
      }).catch(() => {
        Notify.create({
        })
      })
    }, 1000)

    watch(filter, () => {
      page.value = 1
      fetchTerms()
    }, { deep: true })

    const statusGet = (status, key) => statusMap[status][key]

    return {
      page,
      pages,
      total,
      termsList,
      fetchTerms,
      statusGet,
      filter,
      statusMap
    }
  },
  components: {
    AppPagination,
    Layout
  }
}

</script>

<template>
  <Layout :pagetitle="'Termos de Uso'">
    <div class="row">
      <div class="col-lg-12">
        <div class="col-md-12">
          <div
            class="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3"
          >
            <div>
              <ul class="nav nav-pills">
                <div>
                  <router-link
                    :to="{ name: 'terms_new' }"
                    class="btn btn-primary"
                  >
                    <i class="bx bx-plus me-1" /> Novo Termo de Uso
                  </router-link>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-lg-4 mb-2">
                <select
                  v-model="filter.status"
                  class="form-select"
                >
                  <option :value="null">
                    Status
                  </option>
                  <option
                    v-for="(status, key) in statusMap"
                    :key="key"
                    :value="key"
                  >
                    {{ status.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-striped mb-4"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      ID
                    </th>
                    <th scope="col">
                      Título
                    </th>
                    <th scope="col">
                      Status
                    </th>
                    <th scope="col">
                      Criado em
                    </th>
                    <th scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="term in termsList"
                    :key="term.id"
                  >
                    <td>{{ term.id }}</td>
                    <td>{{ term.title }}</td>
                    <td>
                      <span
                        class="badge rounded-pill"
                        :class="statusGet(term.status, 'class')"
                      >
                        {{ statusGet(term.status, 'label') }}
                      </span>
                    </td>
                    <td>{{ term.created_at? $d(term.created_at, 'info') : '' }}</td>
                    <td>
                      <router-link
                        :to="{ name: 'terms_edit', params: { id: term.id } }"
                      >
                        <a
                          data-bs-placement="top"
                          title="Editar"
                          class="px-2 text-primary"
                        ><i class="bx bx-pencil font-size-18" /></a>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
              <app-pagination
                v-model="page"
                class="pagination"
                :pages="pages"
                :range-size="1"
                @update:modelValue="fetchTerms"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
